import React, {useEffect, useState} from "react";
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import LinearProgress from '@mui/material/LinearProgress';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Moment from "moment-timezone";
import Axios from "axios";

export default function OrderTable(props) {

    const reset = props.resetter;
    const [prebuilding, setPrebuilding] = useState(false);
    const [partials, setPartials] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setErr] = useState("");
    // const [partialCounts, setPartialCounts] = useState({});
    const [prebuilts, setPrebuilts] = useState({});
    const [order, setOrder] = useState(props.order);
    const [total, setTotal] = useState(0);
    const [manual, setManualChange] = useState(0);
    const [openRow, setOpenRow] = useState(null);
    const [partiallyCompletedSku, setPartiallyCompletedSku] = useState(false);

    const lang = {
        "en": {
            "order":"Order Number:",
            "customer":"Customer:",
            "qty":"Quantity:",
            "type":"Type:",
            "date":"Order Date:",
            "total":"Total Pieces:",
            "note":"Note:",
            "sku":"SKU",
            "description":"Description",
            "ship":"On Shipments",
            "ordered":"Ordered Qty",
            "partial":"Mark Total Fulfilled",
            "complete":"Complete",
            "submit":"Submit Builds",
            "prebuilt":"Prebuilt",
            "reqEmpl":"Add employees to build order.",
            "search":"Search Again",
            "totalCurr":"Current Total",
            "shipment":"Shipment Number:"
        },
        "es": {
            "order":"Número de Orden:",
            "customer":"Vendedor:",
            "qty":"Cantidad:",
            "type":"Clase:",
            "date":"Fecha de Orden:",
            "total":"Piezas en Total:",
            "note":"Nota:",
            "sku":"SKU",
            "description":"Descripción",
            "ship":"En Envíos",
            "ordered":"Cantidad Ordenada",
            "partial":"Marcar el Total Cumplido",
            "complete":"Completo",
            "submit":"Enviar Compilaciones",
            "prebuilt":"Prediseñado",
            "reqEmpl":"Agregar empleados a la orden de construcción.",
            "search":"Busca de Nuevo",
            "totalCurr": "Total Actual",
            "shipment":"Numero de Envío:"
        }
    }


    function totalMax() {
        let count = 0;
        for(let i = 0; i < order.length; i++) {
            count += order[i].ReqQty - order[i].Shipped;
        }
        setTotal(count);
    }

    function submit() {
        setLoading(true);
        setErr("");
        let out = [];
        let now = Moment(Date.now()).tz("America/New_York").format('YYYY-MM-DD HH:mm:ss');
        let workers = props.employees.map(employee => employee.name).toString();
        for(let i = 0; i < order.length; i++) {
            if(order[i].Shipped === order[i].ReqQty || order[i].Qty === 0) continue;
            out.push([props.orderNbr, props.customer, order[i].Sku, order[i].Qty, now, workers, props.group, props.wh]);
            order[i].Complete = true;
        }
        orderRequest(order, out, prebuilts).then(function() {
            setLoading(false)
            props.refresh();
            reset();
        }).catch(function(err) {
            console.log(err);
            setErr(err.error);
            setLoading(false);
        })
    }

    useEffect(() => {
        totalMax()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // function submitPartials() {
    //     setLoading(true);
    //     let out = [];
    //     let now = Moment(Date.now()).format('YYYY-MM-DD HH:mm:ss');;
    //     let workers = props.employees.map(employee => employee.name).toString();
    //     for(let i = 0; i < order.length; i++) {
    //         let qty = order[i].Qty;
    //         if(order[i].Shipped === order[i].ReqQty) continue;
    //         if(order[i].Sku in partialCounts) {
    //             qty = partialCounts[order[i].Sku];
    //             order[i].Qty = qty;
    //         }
    //         if(order[i].Qty === 0) continue;
    //         out.push([props.orderNbr, props.customer, order[i].Sku, qty, now, workers, props.group, props.wh]);
    //         order[i].Complete = (order[i].Qty + order[i].Shipped === order[i].ReqQty)
    //     }
    //     if(out.length == 0) return;
    //     orderRequest(order, out, prebuilts).then(function() {
    //         setLoading(false)
    //         props.refresh();
    //         reset();
    //     }).catch(function(err) {
    //         console.log(err);
    //         setErr(err.error);
    //     })
    //     // reset();
    // }

    function changePartial(row, value) {
        let val = Number(value);
        let temp = order;
        for(let i = 0; i < temp.length; i++) {
            if(temp[i].Sku === row.Sku) {
                setTotal(total + (val - temp[i].Qty))
                temp[i].Qty = val;
                break;
            }
        }
        setOrder(temp);
        if(row.ReqQty > row.Qty && row.Qty > 0 && props.type === "DROPSHIP"){
            setPartiallyCompletedSku(true); 
        }
        else{
            setPartiallyCompletedSku(false);
        }
    }

    function changePrebuild(row, value) {
        let val = Number(value);
        let temp = {};
        for(let key in prebuilts) {
            if(key === row.Sku) {
                temp[key] = val;
            }
            else {
                temp[key] = prebuilts[key];
            }
        }
        setPrebuilts(temp);
    }

    function startPartial() {
        setPartials(true);
        allMax();
    }

    function allMax() {
        let temp = order;
        for(let i = 0; i < temp.length; i++) {
            temp[i].Qty = order[i].ReqQty - order[i].Shipped;
        }
        setOrder(temp);
        // document.querySelectorAll(".partialInput").forEach(function(node) {
        //     node.value = node.getAttribute("max");
        // });
        totalMax();
    }

    function allNone() {
        let temp = order;
        for(let i = 0; i < temp.length; i++) {
            temp[i].Qty = 0;
        }
        setOrder(temp);
        // document.querySelectorAll(".partialInput").forEach(function(node) {
        //     node.value = node.getAttribute("min");
        // });
        setTotal(0);
        allNonePre();
    }

    function allMaxPre() {
        let temp = {};
        for(let i = 0; i < order.length; i++) {
            if(order[i].ReqQty === order[i].Shipped) continue;
            temp[order[i].Sku] = order[i].ReqQty - order[i].Shipped;
        }
        setPrebuilts(temp);
        allMax();
    }

    function allNonePre() {
        let temp = {};
        for(let i = 0; i < order.length; i++) {
            if(order[i].ReqQty === order[i].Shipped) continue;
            temp[order[i].Sku] = 0;
        }
        setPrebuilts(temp);
    }

    function togglePrebuilt() {
        setPrebuilding(!prebuilding);
        allNonePre();
    }

    function incrementPrebuilt(id, row) {
        let qty = 0;
        if(row.Sku in prebuilts) {
            qty = prebuilts[row.Sku];
        }
        let finalQty = -1;
        if(qty >= row.ReqQty - row.Shipped) {
            finalQty = row.ReqQty - row.Shipped;
        }
        else {
            finalQty = qty + 1;
        }
        if(finalQty > row.Qty) {
            changePartial(row, finalQty);
        }
        // document.getElementById(id).value = finalQty;
        changePrebuild(row, finalQty)
    }

    function decrementPrebuilt(id, row) {
        let qty = 0;
        if(row.Sku in prebuilts) {
            qty = prebuilts[row.Sku];
        }
        let finalQty = 0;
        if(qty > 0) {
            finalQty = qty - 1;
        }
        // document.getElementById(id).value = finalQty;
        changePrebuild(row, finalQty);
    }

    function decrementPartial(id, row) {
        let qty = row.Qty - 1;
        let min = 0;
        if(row.Sku in prebuilts) {
            min = prebuilts[row.Sku]
        }
        if(qty <= min) {
            qty = min;
        }
        // document.getElementById(id).value = qty;
        changePartial(row, qty);
    }

    function incrementPartial(id, row) {
        let qty = row.Qty + 1;
        if(qty >= row.ReqQty - row.Shipped) {
            qty = row.ReqQty - row.Shipped;
        }
        // document.getElementById(id).value = qty;
        changePartial(row, qty)
    }

    function setZero(row) {
        changePrebuild(row, 0);
        changePartial(row, 0);
    }

    function orderRequest(order, out, pre) {
        return new Promise((resolve, reject) => {
            let url = props.base + "/operations/saveBuild";
            let body = {"orders":out, "full":order, "prebuilt":pre, "orderNbr": props.orderNbr, "shipmentNbr": props.shipmentNbr, "customer":props.customer, "type":props.type, "date": Moment(Date.now()).tz("America/New_York").format('YYYY-MM-DD'), "location": props.location || "MAIN", "wh": props.wh,  "line": props.group, "branch": props.branch};
            Axios.put(url, body, {mode: 'no-cors'}).then(() => {resolve()}).catch((error) => {console.log(error); reject(error)});
        })
    }

    function handleClose(row) {
        let val = manual;
        if(manual > row.ReqQty) val = row.ReqQty;
        if(manual < 0) val = 0;
        changePartial(row, val);
        setOpenRow(null);
        setManualChange(0);
    }

    function openPopupRow(row) {
        setOpenRow(row);
        console.log(row);
    }
    
    return (
        <Box display="flex" flexDirection="column">
            <Button disabled={loading} color="info" style={{width:"fit-content", margin:"auto", marginBottom:"25px"}} variant="contained" onClick={()=>props.resetter()}>{lang[props.locale].search}</Button>
            {loading ? <LinearProgress color="success" /> : null }
            {error !== "" ? <Alert severity="error">{error}</Alert> : null}
            <Box display="flex">
                <List>
                    <ListItem>
                        <ListItemText primary={lang[props.locale].order} secondary={props.orderNbr} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={lang[props.locale].date} secondary={props.orderDate} />
                    </ListItem>  
                </List>
                <List>
                    <ListItem>
                        <ListItemText primary={lang[props.locale].customer} secondary={props.customer} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={lang[props.locale].total} secondary={props.totalQty} />
                    </ListItem>
                </List>
                <List>
                    <ListItem>
                        <ListItemText primary={lang[props.locale].type} secondary={props.type} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={lang[props.locale].totalCurr} secondary={total} />
                    </ListItem>
                </List>
                <List>
                    {props.shipmentNbr ?
                        <ListItem>
                            <ListItemText primary={lang[props.locale].shipment} secondary={props.shipmentNbr} />
                        </ListItem>
                        : null}
                    {/* <ListItem>
                        <ListItemText primary={lang[props.locale].note} />
                    </ListItem> */}
                </List>
            </Box>
            {partials ? <Button onClick={() => togglePrebuilt()}>Prebuilts</Button> : null }
            <Dialog onClose={() => handleClose(openRow)} open={openRow !== null}>
                    {openRow !== null ? 
                    <Box>
                        <DialogTitle>Set Quantity Built: {openRow.Sku}</DialogTitle>
                        <TextField style={{width: "80%", margin:"auto"}} InputProps={{ inputProps: { min: 0, max: openRow.Qty } }} type="number" defaultValue={openRow.Qty} variant="outlined" onChange={(e)=>setManualChange(e.target.value)}/>
                        <Button onClick={() => handleClose(openRow)}>Save</Button> 
                    </Box>
                    : null}
            </Dialog>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {lang[props.locale].sku}
                            </TableCell>
                            <TableCell>
                                {lang[props.locale].description}
                            </TableCell>
                            <TableCell>
                                {lang[props.locale].ship}
                            </TableCell>
                            <TableCell>
                                {lang[props.locale].ordered}
                            </TableCell>
                            {prebuilding ? <TableCell><Box display="flex" flexDirection="column">{lang[props.locale].prebuilt}<Box display="flex"><Button variant="outlined" color="error" style={{borderBottomRightRadius:"0", borderTopRightRadius:"0"}} onClick={()=>allNonePre()}>None</Button><Button style={{borderBottomLeftRadius:"0", borderTopLeftRadius:"0"}} variant="outlined" color="success" onClick={()=>allMaxPre()}>Max</Button></Box></Box></TableCell> : null}
                            {partials ? <TableCell><Box display="flex" flexDirection="column">{lang[props.locale].partial}<Box display="flex"><Button variant="outlined" color="error" style={{borderBottomRightRadius:"0", borderTopRightRadius:"0"}} onClick={()=>allNone()}>None</Button><Button style={{borderBottomLeftRadius:"0", borderTopLeftRadius:"0"}} variant="outlined" color="success" onClick={()=>allMax()}>Max</Button></Box></Box></TableCell> : null}
                            {partials ? <TableCell /> : null}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {order.map((row, index) => (
                            (!row.nonStock ? <TableRow style={{background: row.Shipped === row.ReqQty ? '#EBECF0' : 'white'}} key={"row" + index}>
                                <TableCell style={{color: row.Shipped === row.ReqQty ? 'grey' : 'black'}} component="th" scope="row">
                                    {row.Sku}
                                </TableCell>
                                <TableCell style={{color: row.Shipped === row.ReqQty ? 'grey' : 'black'}} >
                                    {row.Description}
                                </TableCell>
                                <TableCell style={{color: row.Shipped === row.ReqQty ? 'grey' : 'black'}} >
                                    {row.Shipped > 0 ? row.Shipped + " Built" : null }
                                </TableCell>
                                <TableCell style={{color: row.Shipped === row.ReqQty ? 'grey' : 'black'}} >
                                    {row.ReqQty}
                                </TableCell>
                                {prebuilding ? row.Shipped !== row.ReqQty ? <TableCell><Box display="flex" style={{width:"fit-content",height:"fit-content"}}><Button onClick={() => decrementPrebuilt("prebuilt" + index, row)} variant="outlined" color="error" style={{borderBottomRightRadius:"0", borderTopRightRadius:"0", fontSize:"24px", height:"30px", width:"12px"}}>-</Button>
                                    {/* <input id={"prebuilt" + index} pattern="[0-9]" type="number" className="prebuiltInput" max={row.ReqQty - row.Shipped} min='0' style={{width: "fit-content", fontSize:"20px", height:"24px", borderRadius:"0"}} onChange={(e) => changePrebuild(row, e.target.value)}  value="0" /> */}
                                    <Box style={{margin:"auto", fontSize:"16px", padding:"8px"}}>{prebuilts[row.Sku]}</Box>
                                    <Button onClick={() => incrementPrebuilt("prebuilt" + index, row)} variant="outlined" color="success" style={{fontSize:"24px", height:"30px", width:"12px", borderBottomLeftRadius:"0", borderTopLeftRadius:"0"}}>+</Button></Box></TableCell> : <TableCell /> : null}
                                {partials ? row.Shipped !== row.ReqQty ? <TableCell>
                                    <Box display="flex" style={{width:"fit-content",height:"fit-content"}}><Button onClick={() => decrementPartial("partial" + index, row)} variant="outlined" color="error" style={{borderBottomRightRadius:"0", borderTopRightRadius:"0", fontSize:"24px", height:"30px", width:"12px"}}>-</Button>
                                    {/* <input pattern="[0-9]" id={"partial" + index} type="number" className="partialInput" max={row.ReqQty - row.Shipped} min='0' style={{width: "fit-content", fontSize:"20px", height:"24px", borderRadius:"0"}} onChange={(e) => changePartial(row, e.target.value)}  value={row.Qty - row.Shipped} /> */}
                                    <Box  style={{margin:"auto", fontSize:"16px", padding:"8px"}}>{row.Qty}</Box>
                                    <Button onClick={() => incrementPartial("partial" + index, row)} variant="outlined" color="success" style={{fontSize:"24px", height:"30px", width:"12px", borderBottomLeftRadius:"0", borderTopLeftRadius:"0"}}>+</Button></Box></TableCell> : <TableCell /> : null}
                                    {partials ? row.Shipped !== row.ReqQty ? <TableCell><Button variant="contained" color="secondary" size="small" onClick={()=>openPopupRow(row)}>Set Value</Button></TableCell> : <TableCell /> : null}
                            </TableRow> : null )
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box display="flex" style={{marginTop:"25px", marginBottom:"25px"}}>
            {props.employees.length > 0 ? !partials ? <Box display="flex" style={{margin:"auto", marginTop:"30px", width:"50%"}}>
                <Button disabled={loading || total <= 0} variant="outlined" style={{margin:"auto"}} onClick={() => startPartial()}>{lang[props.locale].partial} {loading ? <CircularProgress size={24} style={{color:'green', position:'absolute'}}></CircularProgress> : null }</Button>
                {/* <Button disabled={loading} variant="outlined" style={{marginLeft:"auto"}} onClick={() => submit()}>{lang[props.locale].complete} {loading ? <CircularProgress size={24} style={{color:'green', position:'absolute'}}></CircularProgress> : null }</Button> */}
            </Box> : <Button disabled={loading || !total || partiallyCompletedSku }  style={{margin: "auto"}} onClick={() => submit()}>{lang[props.locale].submit} {loading ? <CircularProgress size={24} style={{color:'green', position:'absolute',marginLeft:"auto"}}></CircularProgress> : null }</Button> : <span style={{fontSize:"24px", fontWeight:"bold", margin:"auto"}}>{lang[props.locale].reqEmpl} </span>}
            </Box>
        </Box>
    )
}
